
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as _91_46_46_46page_93B6221gClypHIxjPWduJSW1WcqVmtTSAgv2lPPCKtC3oMeta } from "/vercel/path0/pages/[...page].vue?macro=true";
import { default as contact6cZoOGxJBTY9l_45DSf37OWQKprIBU1PffJx7PVn14dCsMeta } from "/vercel/path0/pages/contact.vue?macro=true";
import { default as _91item_93SBY8zAuBqS09EVm6tWZINZnYQYsZvwyMGOqaubs79esMeta } from "/vercel/path0/pages/events/[item].vue?macro=true";
import { default as index5m4luOcQHke3VZrEYSUnzYkzST3dh3H3ZkRLIEILJ48Meta } from "/vercel/path0/pages/events/index.vue?macro=true";
import { default as faqjjKwHwl3gHPshJy6Fwl0mtVaKy4g6wmpINdRy4T3GwoMeta } from "/vercel/path0/pages/faq.vue?macro=true";
import { default as galleryDLxFKVENimRRVJJ_459ELiRq_45VfjTVrdrzneLKnokQEnkMeta } from "/vercel/path0/pages/gallery.vue?macro=true";
import { default as highlightsFtq_45vGp_5Sa6uu0A_45qfY5jjT_r_CTr7r70jkJpMRqc4Meta } from "/vercel/path0/pages/highlights.vue?macro=true";
import { default as indexG_WfXOzFIdmY7nC2wskK9_5LGE8hGwIJyZEyCaXLOuMMeta } from "/vercel/path0/pages/index.vue?macro=true";
import { default as meetingformtuZG9J_45E28uJhtjKoToSNc7SkxNB_c_45orihlKcLBfEkMeta } from "/vercel/path0/pages/meetingform.vue?macro=true";
import { default as _91item_93jNm0OJHsrIKzLT_hoCtSwhUQWpBMFxpLCGiV3HpwjK0Meta } from "/vercel/path0/pages/meetingpackages/[item].vue?macro=true";
import { default as index27NtJibUesXU0_45gV9RjsyqxNyypQXjZQlLiHTmM8WPAMeta } from "/vercel/path0/pages/meetingpackages/index.vue?macro=true";
import { default as _91item_93mzUr_45jQowVO0j9ivZ1SFFWBHD_XzI2jUp_cuf1XoISUMeta } from "/vercel/path0/pages/meetingrooms/[item].vue?macro=true";
import { default as indexhZ_45tozciX2zm16yBau6AI8uLHzmq8rNqpEDNOVcsY9YMeta } from "/vercel/path0/pages/meetingrooms/index.vue?macro=true";
import { default as _91item_93gCoMJRgYbQ4LeChe2eMBYWADUR70hcy33O1I9onZxacMeta } from "/vercel/path0/pages/news/[item].vue?macro=true";
import { default as indexna0DTdJ9MOcZDj_Pn7omZm8Tb_trwqDATTqect1_45xAIMeta } from "/vercel/path0/pages/news/index.vue?macro=true";
import { default as _91item_939C8kULePuMOZkF6y8V95ZLVBcRP1Lk2y8Tk8ECdGARwMeta } from "/vercel/path0/pages/packages/[item].vue?macro=true";
import { default as indexZn_45bAcd39gGp3B0BjAFVKy86eJy9pe0_68j0ApHVaqMMeta } from "/vercel/path0/pages/packages/index.vue?macro=true";
import { default as reviewssJjJ9Q3KaOsW6OaQfQCHMlf7POWTAro7_mpjJ8HZ6i0Meta } from "/vercel/path0/pages/reviews.vue?macro=true";
import { default as _91item_93h1jfaZ0LMC4g7gWJiuxffsG7UnJguRUVyOO_45dojPxMIMeta } from "/vercel/path0/pages/rooms/[item].vue?macro=true";
import { default as indexZdbeiSLGH5QVPn2J_weMTpHeymMl22S4LMxJcrOTUAEMeta } from "/vercel/path0/pages/rooms/index.vue?macro=true";
import { default as sitemapnpadVI10GvN_45Y_45o1so1bwWJcyYretVyWs_xkYJE7aFUMeta } from "/vercel/path0/pages/sitemap.vue?macro=true";
export default [
  {
    name: "page___en",
    path: "/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___nl",
    path: "/nl/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___de",
    path: "/de/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___es",
    path: "/es/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "page___fr",
    path: "/fr/:page(.*)*",
    component: () => import("/vercel/path0/pages/[...page].vue")
  },
  {
    name: "contact___en",
    path: "/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___nl",
    path: "/nl/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___de",
    path: "/de/kontakt",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___es",
    path: "/es/contacto",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "contact___fr",
    path: "/fr/contact",
    component: () => import("/vercel/path0/pages/contact.vue")
  },
  {
    name: "events-item___en",
    path: "/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue")
  },
  {
    name: "events-item___nl",
    path: "/nl/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue")
  },
  {
    name: "events-item___de",
    path: "/de/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue")
  },
  {
    name: "events-item___es",
    path: "/es/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue")
  },
  {
    name: "events-item___fr",
    path: "/fr/events/:item()",
    component: () => import("/vercel/path0/pages/events/[item].vue")
  },
  {
    name: "events___en",
    path: "/events",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "events___nl",
    path: "/nl/events",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "events___de",
    path: "/de/events",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "events___es",
    path: "/es/events",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "events___fr",
    path: "/fr/events",
    component: () => import("/vercel/path0/pages/events/index.vue")
  },
  {
    name: "faq___en",
    path: "/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___nl",
    path: "/nl/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___de",
    path: "/de/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___es",
    path: "/es/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "faq___fr",
    path: "/fr/faq",
    component: () => import("/vercel/path0/pages/faq.vue")
  },
  {
    name: "gallery___en",
    path: "/gallery",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___nl",
    path: "/nl/galerij",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___de",
    path: "/de/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___es",
    path: "/es/galeria",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "gallery___fr",
    path: "/fr/galerie",
    component: () => import("/vercel/path0/pages/gallery.vue")
  },
  {
    name: "highlights___en",
    path: "/location",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___nl",
    path: "/nl/locatie",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___de",
    path: "/de/standort",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___es",
    path: "/es/ubicacion",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "highlights___fr",
    path: "/fr/site",
    component: () => import("/vercel/path0/pages/highlights.vue")
  },
  {
    name: "index___en",
    path: "/",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___nl",
    path: "/nl",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___de",
    path: "/de",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___es",
    path: "/es",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "index___fr",
    path: "/fr",
    component: () => import("/vercel/path0/pages/index.vue")
  },
  {
    name: "meetingform___en",
    path: "/meeting-form",
    component: () => import("/vercel/path0/pages/meetingform.vue")
  },
  {
    name: "meetingform___nl",
    path: "/nl/vergaderformulier",
    component: () => import("/vercel/path0/pages/meetingform.vue")
  },
  {
    name: "meetingform___de",
    path: "/de/besprechungsformular",
    component: () => import("/vercel/path0/pages/meetingform.vue")
  },
  {
    name: "meetingform___es",
    path: "/es/forma-de-reunion",
    component: () => import("/vercel/path0/pages/meetingform.vue")
  },
  {
    name: "meetingform___fr",
    path: "/fr/formulaire-de-reunion",
    component: () => import("/vercel/path0/pages/meetingform.vue")
  },
  {
    name: "meetingpackages-item___en",
    path: "/meeting-packages/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages-item___nl",
    path: "/nl/vergaderarrangementen/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages-item___de",
    path: "/de/tagungspakete/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages-item___es",
    path: "/es/paquetes-de-reuniones/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages-item___fr",
    path: "/fr/paquets-de-reunions/:item()",
    component: () => import("/vercel/path0/pages/meetingpackages/[item].vue")
  },
  {
    name: "meetingpackages___en",
    path: "/meeting-packages",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingpackages___nl",
    path: "/nl/vergaderarrangementen",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingpackages___de",
    path: "/de/tagungspakete",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingpackages___es",
    path: "/es/paquetes-de-reuniones",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingpackages___fr",
    path: "/fr/paquets-de-reunions",
    component: () => import("/vercel/path0/pages/meetingpackages/index.vue")
  },
  {
    name: "meetingrooms-item___en",
    path: "/meeting-rooms/:item()",
    component: () => import("/vercel/path0/pages/meetingrooms/[item].vue")
  },
  {
    name: "meetingrooms-item___nl",
    path: "/nl/vergaderzalen/:item()",
    component: () => import("/vercel/path0/pages/meetingrooms/[item].vue")
  },
  {
    name: "meetingrooms-item___de",
    path: "/de/tagungsraume/:item()",
    component: () => import("/vercel/path0/pages/meetingrooms/[item].vue")
  },
  {
    name: "meetingrooms-item___es",
    path: "/es/salas-de-reuniones/:item()",
    component: () => import("/vercel/path0/pages/meetingrooms/[item].vue")
  },
  {
    name: "meetingrooms-item___fr",
    path: "/fr/salles-de-reunion/:item()",
    component: () => import("/vercel/path0/pages/meetingrooms/[item].vue")
  },
  {
    name: "meetingrooms___en",
    path: "/meeting-rooms",
    component: () => import("/vercel/path0/pages/meetingrooms/index.vue")
  },
  {
    name: "meetingrooms___nl",
    path: "/nl/vergaderzalen",
    component: () => import("/vercel/path0/pages/meetingrooms/index.vue")
  },
  {
    name: "meetingrooms___de",
    path: "/de/tagungsraume",
    component: () => import("/vercel/path0/pages/meetingrooms/index.vue")
  },
  {
    name: "meetingrooms___es",
    path: "/es/salas-de-reuniones",
    component: () => import("/vercel/path0/pages/meetingrooms/index.vue")
  },
  {
    name: "meetingrooms___fr",
    path: "/fr/salles-de-reunion",
    component: () => import("/vercel/path0/pages/meetingrooms/index.vue")
  },
  {
    name: "news-item___en",
    path: "/news/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___nl",
    path: "/nl/nieuws/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___de",
    path: "/de/nachrichten/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___es",
    path: "/es/noticias/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news-item___fr",
    path: "/fr/actualites/:item()",
    component: () => import("/vercel/path0/pages/news/[item].vue")
  },
  {
    name: "news___en",
    path: "/news",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___nl",
    path: "/nl/nieuws",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___de",
    path: "/de/nachrichten",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___es",
    path: "/es/noticias",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "news___fr",
    path: "/fr/actualites",
    component: () => import("/vercel/path0/pages/news/index.vue")
  },
  {
    name: "packages-item___en",
    path: "/special-offers/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___nl",
    path: "/nl/arrangementen/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___de",
    path: "/de/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___es",
    path: "/es/arreglos/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages-item___fr",
    path: "/fr/arrangements/:item()",
    component: () => import("/vercel/path0/pages/packages/[item].vue")
  },
  {
    name: "packages___en",
    path: "/special-offers",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___nl",
    path: "/nl/arrangementen",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___de",
    path: "/de/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___es",
    path: "/es/arreglos",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "packages___fr",
    path: "/fr/arrangements",
    component: () => import("/vercel/path0/pages/packages/index.vue")
  },
  {
    name: "reviews___en",
    path: "/reviews",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___nl",
    path: "/nl/beoordelingen",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___de",
    path: "/de/rezensionen",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___es",
    path: "/es/resenas",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "reviews___fr",
    path: "/fr/revues",
    component: () => import("/vercel/path0/pages/reviews.vue")
  },
  {
    name: "rooms-item___en",
    path: "/rooms/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___nl",
    path: "/nl/kamers/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___de",
    path: "/de/zimmer/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___es",
    path: "/es/habitaciones/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms-item___fr",
    path: "/fr/chambres/:item()",
    component: () => import("/vercel/path0/pages/rooms/[item].vue")
  },
  {
    name: "rooms___en",
    path: "/rooms",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___nl",
    path: "/nl/kamers",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___de",
    path: "/de/zimmer",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___es",
    path: "/es/habitaciones",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "rooms___fr",
    path: "/fr/chambres",
    component: () => import("/vercel/path0/pages/rooms/index.vue")
  },
  {
    name: "sitemap___en",
    path: "/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___nl",
    path: "/nl/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___de",
    path: "/de/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___es",
    path: "/es/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  },
  {
    name: "sitemap___fr",
    path: "/fr/sitemap",
    component: () => import("/vercel/path0/pages/sitemap.vue")
  }
]